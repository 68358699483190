import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, TransparencyCaseCategoryDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-transparency-case-category-item-form',
  templateUrl: './transparency-case-category-item-form.component.html',
  styleUrl: './transparency-case-category-item-form.component.scss',
})
export class TransparencyCaseCategoryItemFormComponent extends BaseForm<TransparencyCaseCategoryDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'TRANSPARENCY_CASE_CATEGORY');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? {
          ...this.getDataKeyValueFormat(),
          linkedDocuments: this.formGroup.controls.icon.value ? [this.formGroup.controls.icon.value] : [],
        }
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      approver: new FormControl(null, Validators.required),
      questionnaire: new FormControl(null, Validators.required),
      reviewers: new FormControl(null, Validators.required),
      icon: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
