<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="'Approver'"
        [placeholder]="'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.approver"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="'Questionnaire'"
        [placeholder]="'Search Questionnaire'"
        [control]="formGroup?.controls?.questionnaire"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [targetTypes]="['QUESTIONNAIRE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-tree-selector
        [principleModeSelection]="'SELECTION'"
        [label]="'Reviewers'"
        [placeholder]="'Search...'"
        [control]="formGroup?.controls?.reviewers"
        [multi]="true"
        [viewMode]="fieldViewMode"
        [selectedEntities]="data?.reviewers"
        [useResponsibilityMode]="true"
      >
      </app-user-and-group-tree-selector>
    </div>
    <div class="col-12">
      <app-icon-selector [control]="formGroup?.controls?.icon" [viewMode]="fieldViewMode"> </app-icon-selector>
      <!-- <app-attachment-input
        [label]="'Icon'"
        [control]="formGroup?.controls?.icon"
        [viewMode]="fieldViewMode"
        [dragAndDropMode]="true"
        [multiple]="false"
        [uploadLocation]="'CODE'"
        [accept]="'.svg'"
        [useGlobalPasteEventListener]="false"
        [staticUploadWidth]="false"
      ></app-attachment-input> -->
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'100px'"
        [name]="'textarea'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
